import React from 'react'
import SlowDownComp from './SlowDownComp';
import MasterImg from '../../assets/images/Extension/Guide.svg';
import ActionwidgetImg from '../../assets/images/Extension/ActionWidget.svg';

const FeedbackReason = ({
  formState,
  checkedValue,
  reasonText,
  handleChangeText,
}) => {
  // const [mode, setMode] = useState('in-out');
  return (
    <div className={`Div2 absolute ${checkedValue ? 'slide-in' : 'hidden'}`}>
      {/* <div className={`Div2 ${checkedValue ? 'slide-in' : 'hidden'}`}> */}
      {formState.slow_down_pc && checkedValue === 'slow_down_pc' && (
        <SlowDownComp
          inputValue={reasonText.slow_down_pc}
          handleChangeText={handleChangeText}
          titleText="We work around the clock to fix it!"
          text="If you hold tight a little longer, we think you'll see an improvement soon :-)"
          name="slow_down_pc"
          placeholderT="Please provide some details of your problem"
          buttonText="Continue to use DCDial"
          isInput
        />
      )}
      {formState.not_working && checkedValue === 'not_working' && (
        <SlowDownComp
          inputValue={reasonText.not_working}
          handleChangeText={handleChangeText}
          titleText="Where do you prefer to use DCDial?"
          text="If you hold tight a little longer, we think you'll see an improvement soon :-)"
          name="not_working"
          placeholderT="Please provide the website link or the name of the app"
          buttonText="Continue to use DCDial"
          isInput
        />
      )}
      {formState.donotknow && checkedValue === 'donotknow' && (
        <SlowDownComp
          inputValue={reasonText.slow_down_pc}
          handleChangeText={handleChangeText}
          titleText="Master it in three minutes!"
          isImage
          buttonWidth="274px"
          image={MasterImg}
          text="Check the DCDial <span class='underLineText'><a href='https://support.dcdial.com/category/dcdial-copilot' target='_blank'>Quick Guide</a></span>. We hope you’ll enjoy using it."
          buttonText="Boost efficiency with DCDial"
        />
      )}
      {formState.difficult2use && checkedValue === 'difficult2use' && (
        <SlowDownComp
          inputValue={reasonText.difficult2use}
          handleChangeText={handleChangeText}
          titleText="We will turn off the quick action widget for you!"
          isImage
          buttonWidth="254px"
          image={ActionwidgetImg}
          text="If you need it later on, you can visit your settings and turn on the widget."
          buttonText="Turn off the DCDial widget"
        />
      )}
      {formState.product_manager && checkedValue === 'product_manager' && (
        <SlowDownComp
          inputValue={reasonText.product_manager}
          handleChangeText={handleChangeText}
          titleText="Let's vent!🔥"
          name="product_manager"
          placeholderT="Your feedback will be directly delivered to DCDial’s product manager."
          buttonText="Continue to use DCDial"
          isInput
        />
      )}
      {formState.find_better_tool && checkedValue === 'find_better_tool' && (
        <SlowDownComp
          inputValue={reasonText.find_better_tool}
          handleChangeText={handleChangeText}
          titleText="What are your preferred tools?"
          text="We will strive to do better. Thank you for your patience."
          name="find_better_tool"
          placeholderT="Please provide the name of AI extensions or tools you prefer"
          buttonText="Continue to use DCDial"
          isInput
        />
      )}
      {formState.privacy_security_concern &&
        checkedValue === 'privacy_security_concern' && (
          <SlowDownComp
            inputValue={reasonText.privacy_security_concern}
            handleChangeText={handleChangeText}
            titleText="🔒 We care deeply about privacy"
            text="We only request permissions needed to deliver the best experience possible. <span class='underLineText'><a href='https://www.dcdial.com/legal/dpa' target='_blank'>Here's</a></span> an article with more details about how DCDial treats data."
            name="privacy_security_concern"
            buttonText="Continue to use DCDial"
          />
        )}
    </div>
  );
};

export default FeedbackReason