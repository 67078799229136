import React, { useEffect, useState } from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import {
  ForCustomer,
  ForDevelopers,
  ForEveryone,
  InfoIcon,
} from '../../assets/Icons/Legal/LegalIcons';
import { useNavigate } from 'react-router-dom';

const LegalGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [legalGroups, setLegalGroups] = useState([]);
  const [handleIndex, setHandleIndex] = useState({
    mainIndex: null,
    subIndex: null,
  });
  useEffect(() => {
    setLegalGroups(getTranslatedList(t));
  }, [t]);

  const handleNavigate = (link) => {
    navigate(link);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };
  return (
    <CommonSizeContainer>
      <div className="w-full grid grid-cols-3 gap-[30px] max_xl:gap-5 max_md:grid-cols-2 max_md:gap-[30px] max_720:grid-cols-1">
        {legalGroups.map((groups, index) => (
          <div
            className="rounded-[20px] p-[30px] max_xl:p-[20px] max_md:p-[30px] border-[1px] border-LightOutline flex flex-col gap-6 w-[360px] max_xl:w-[320px] max_md:w-full max_sm:p-4"
            key={index}
          >
            <div>{groups.icon}</div>
            <p className="subHeadingText">{groups.title}</p>
            <p className="subParagraphText font-normal tracking-[-1px] max_xl:min-h-[180px] max_md:min-h-max">
              {groups.text}
            </p>
            <div className="w-full h-[1px] bg-LightOutline"></div>
            {groups.list.map((list, index1) => (
              <div className="flex gap-1" key={index1}>
                <div
                  className="cursor-pointer subParagraphText text-ButtonPrimary font-normal tracking-[-1px]"
                  onClick={() => handleNavigate(list.link)}
                >
                  {list.title}
                </div>
                <div
                  className="cursor-pointer mt-[6px] max_sm:mt-[3px] relative h-max"
                  onMouseEnter={() =>
                    setHandleIndex({ mainIndex: index, subIndex: index1 })
                  }
                  onMouseLeave={() =>
                    setHandleIndex({ mainIndex: null, subIndex: null })
                  }
                >
                  <InfoIcon
                    color={
                      handleIndex.mainIndex === index &&
                      handleIndex.subIndex === index1
                        ? '#1263C8'
                        : '#00A0FC'
                    }
                  />
                  {handleIndex.mainIndex === index &&
                    handleIndex.subIndex === index1 && (
                      <>
                        <div className="absolute right-[-20px] bottom-[30px] bg-ElementPrimary w-[220px] p-[10px] text-textExtraSmall font-DMSansFont font-normal text-LightPrimary rounded-[10px]">
                          {list.text}
                        </div>
                        <div
                          className="absolute right-0 bottom-[21px] w-[20px] h-[10px] bg-ElementPrimary"
                          style={{ clipPath: 'polygon(51% 100%, 0 0, 100% 0)' }}
                        ></div>
                      </>
                    )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t) => {
  const services = [
    {
      id: 1,
      title: t('ForCustomer'),
      text: t('ForCustomerT'),
      icon: <ForCustomer />,
      list: [
        {
          id: 1,
          title: t('ForCustomer1Title'),
          text: t('ForCustomer1Text'),
          link: '/legal/terms-of-service',
        },
        {
          id: 2,
          title: t('ForCustomer2Title'),
          text: t('ForCustomer2Text'),
          link: '/legal/product-specific-terms',
        },
        {
          id: 3,
          title: t('ForCustomer3Title'),
          text: t('ForCustomer3Text'),
          link: '/legal/dpa',
        },
        {
          id: 4,
          title: t('ForCustomer4Title'),
          text: t('ForCustomer4Text'),
          link: '/legal/acceptable-use',
        },
      ],
    },
    {
      id: 2,
      title: t('ForDevelopers'),
      text: t('ForDevelopersT'),
      icon: <ForDevelopers />,
      list: [
        {
          id: 1,
          title: t('ForDevelopers1Title'),
          text: t('ForDevelopers1Text'),
          link: '/legal/developer-terms',
        },
        {
          id: 2,
          title: t('ForDevelopers2Title'),
          text: t('ForDevelopers2Text'),
          link: '/legal/developer-policy',
        },
        {
          id: 3,
          title: t('ForDevelopers3Title'),
          text: t('ForDevelopers3Text'),
          link: '/legal/acceptable-use',
        },
      ],
    },
    {
      id: 3,
      title: t('ForEveryone'),
      text: t('ForEveryoneT'),
      icon: <ForEveryone />,
      list: [
        {
          id: 1,
          title: t('ForEveryone1Title'),
          text: t('ForEveryone1Text'),
          link: '/legal/website-terms-of-use',
        },
        {
          id: 2,
          title: t('ForEveryone2Title'),
          text: t('ForEveryone2Text'),
          link: '/legal/website-accessibility',
        },
        {
          id: 3,
          title: t('ForEveryone3Title'),
          text: t('ForEveryone3Text'),
          link: '/legal/privacy-policy',
        },
        {
          id: 4,
          title: t('ForEveryone4Title'),
          text: t('ForEveryone4Text'),
          link: '/legal/cookie-policy',
        },
        {
          id: 5,
          title: t('ForEveryone5Title'),
          text: t('ForEveryone5Text'),
          link: '/legal/digital-services-act',
        },
      ],
    },
  ];

  return services;
};

export default LegalGroups;
