import React, { useEffect, useRef, useState } from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import QuestionAnswer from './QuestionAnswer';
import ToggleTab from '../ToggleTab';

const AllQuestions = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState('');
  const sectionRefs = useRef({
    general: React.createRef(),
    started: React.createRef(),
    communication: React.createRef(),
    payments: React.createRef(),
    prices: React.createRef(),
    support: React.createRef(),
    security: React.createRef(),
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            const foundObject = quesAnsType.find(
              (item) => item.id === entry.target.id
            );
            setSelectedValue(foundObject);
          }
        });
      },
      { threshold: 0.3 }
    ); // Adjust threshold to when you consider the section "active"

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs.current).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  const [quesAnsType] = useState([
    {
      id: 'general',
      name: t('General'),
    },
    {
      id: 'started',
      name: t('GettingStarted'),
    },
    {
      id: 'communication',
      name: t('Communication'),
    },
    {
      id: 'payments',
      name: t('Payments'),
    },
    {
      id: 'prices',
      name: t('PricesBilling'),
    },
    {
      id: 'support',
      name: t('Support'),
    },
    {
      id: 'security',
      name: t('SecurityCompliance'),
    },
  ]);
  const [selectedValue, setSelectedValue] = useState(quesAnsType[0]);

  const [generalQues] = useState([
    {
      question: t('GeneralQue1'),
      answer: t('GeneralAns1'),
    },
    {
      question: t('GeneralQue2'),
      answer: t('GeneralAns2'),
    },
    {
      question: t('GeneralQue3'),
      answer: t('GeneralAns3'),
    },
    {
      question: t('GeneralQue4'),
      answer: t('GeneralAns4'),
    },
    {
      question: t('GeneralQue5'),
      answer: t('GeneralAns5'),
    },
    {
      question: t('GeneralQue6'),
      answer: t('GeneralAns6'),
    },
    {
      question: t('GeneralQue7'),
      answer: t('GeneralAns7'),
    },
    {
      question: t('GeneralQue8'),
      answer: t('GeneralAns8'),
    },
  ]);
  const [getStartedQues] = useState([
    {
      question: t('GetStartedQues1'),
      answer: t('GetStartedAns1'),
    },
    {
      question: t('GetStartedQues2'),
      answer: t('GetStartedAns2'),
    },
    {
      question: t('GetStartedQues3'),
      answer: t('GetStartedAns3'),
    },
    {
      question: t('GetStartedQues4'),
      answer: t('GetStartedAns4'),
    },
  ]);
  const [communicationQues] = useState([
    {
      question: t('CommunicationQues1'),
      answer: t('CommunicationAns1'),
    },
    {
      question: t('CommunicationQues2'),
      answer: t('CommunicationAns2'),
    },
    // {
    //   question: t('CommunicationQues3'),
    //   answer: t('CommunicationAns3'),
    // },
    {
      question: t('CommunicationQues4'),
      answer: t('CommunicationAns4'),
    },
    {
      question: t('CommunicationQues5'),
      answer: t('CommunicationAns5'),
    },
    {
      question: t('CommunicationQues6'),
      answer: t('CommunicationAns6'),
    },
  ]);
  const [paymentsQues] = useState([
    {
      question: t('PaymentsQue1'),
      answer: t('PaymentsAns1'),
    },
    {
      question: t('PaymentsQue2'),
      answer: t('PaymentsAns2'),
    },
    {
      question: t('PaymentsQue3'),
      answer: t('PaymentsAns3'),
    },
    {
      question: t('PaymentsQue4'),
      answer: t('PaymentsAns4'),
    },
    {
      question: t('PaymentsQue5'),
      answer: t('PaymentsAns5'),
    },
    {
      question: t('PaymentsQue6'),
      answer: t('PaymentsAns6'),
    },
    {
      question: t('PaymentsQue7'),
      answer: t('PaymentsAns7'),
    },
    {
      question: t('PaymentsQue8'),
      answer: t('PaymentsAns8'),
    },
    {
      question: t('PaymentsQue9'),
      answer: t('PaymentsAns9'),
    },
    {
      question: t('PaymentsQue10'),
      answer: t('PaymentsAns10'),
    },
    // {
    //   question: t('PaymentsQue11'),
    //   answer: t('PaymentsAns11'),
    // },
    {
      question: t('PaymentsQue12'),
      answer: t('PaymentsAns12'),
    },
  ]);
  const [pricesQues] = useState([
    {
      question: t('PricesQues1'),
      answer: t('PricesAns1'),
    },
    {
      question: t('PricesQues2'),
      answer: t('PricesAns2'),
    },
    {
      question: t('PricesQues3'),
      answer: t('PricesAns3'),
    },
    {
      question: t('PricesQues4'),
      answer: t('PricesAns4'),
    },
    {
      question: t('PricesQues5'),
      answer: t('PricesAns5'),
    },
    {
      question: t('PricesQues6'),
      answer: t('PricesAns6'),
    },
    {
      question: t('PricesQues7'),
      answer: t('PricesAns7'),
    },
    {
      question: t('PricesQues8'),
      answer: t('PricesAns8'),
    },
    {
      question: t('PricesQues9'),
      answer: t('PricesAns9'),
    },
  ]);
  const [supportQues] = useState([
    {
      question: t('SupportQue1'),
      answer: t('SupportAns1'),
    },
    {
      question: t('SupportQue2'),
      answer: t('SupportAns2'),
    },
  ]);
  const [securityQues] = useState([
    {
      question: t('SecurityComplianceQue1'),
      answer: t('SecurityComplianceAns1'),
    },
    {
      question: t('SecurityComplianceQue2'),
      answer: t('SecurityComplianceAns2'),
    },
    {
      question: t('SecurityComplianceQue3'),
      answer: t('SecurityComplianceAns3'),
    },
    {
      question: t('SecurityComplianceQue4'),
      answer: t('SecurityComplianceAns4'),
    },
    {
      question: t('SecurityComplianceQue5'),
      answer: t('SecurityComplianceAns5'),
    },
    {
      question: t('SecurityComplianceQue6'),
      answer: t('SecurityComplianceAns6'),
    },
    {
      question: t('SecurityComplianceQue7'),
      answer: t('SecurityComplianceAns7'),
    },
  ]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="hidden max_md:block mt-10 max_sm:mt-5 max_md:px-10 m-auto max_sm:px-5">
        <div className="px-20 flex justify-center max_md:px-0 m-auto">
          <ToggleTab
            selected={selectedValue}
            setSelected={setSelectedValue}
            pricingOptions={quesAnsType}
            handleClickScroll={handleClickScroll}
            isQuestion
          />
        </div>
      </div>
      <CommonSizeContainer>
        <div className="flex justify-between">
          {/* QUESTION TYPE */}
          <div className="w-[220px] flex flex-col gap-2 h-fit border-l-[1px] border-LightOutline max_md:hidden sticky top-16">
            {quesAnsType.map((quesHead, index) => (
              <React.Fragment key={index}>
                <p
                  className={`px-5 py-3 smallText16 cursor-pointer transition-colors duration-400 ${
                    quesHead.name === selectedValue.name
                      ? 'bg-ButtonPrimary text-white'
                      : 'text-LightSecondary'
                  }`}
                  onClick={() => {
                    handleClickScroll(quesHead.id);
                    // setSelectedValue(quesHead);
                  }}
                >
                  {quesHead.name}
                </p>
              </React.Fragment>
            ))}
          </div>
          <div className="max-w-[850px] max_xl:max-w-[730px] max_md:max-w-[100%]">
            {/* GENERAL QUESTIONS */}
            <div id="general" ref={sectionRefs.current.general}>
              <p className="text40class">{t('General')}</p>

              {generalQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
            {/* GETTING STARTED QUESTIONS */}
            <div
              className="mt-20 max_md:mt-[60px] max_sm:mt-10"
              id="started"
              ref={sectionRefs.current.started}
            >
              <p className="text40class">{t('GettingStarted')}</p>

              {getStartedQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
            {/* COMMUNICATION QUESTIONS */}
            <div
              className="mt-20 max_md:mt-[60px] max_sm:mt-10"
              id="communication"
              ref={sectionRefs.current.communication}
            >
              <p className="text40class">{t('Communication')}</p>

              {communicationQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
            {/* Payments QUESTIONS */}
            <div
              className="mt-20 max_md:mt-[60px] max_sm:mt-10"
              id="payments"
              ref={sectionRefs.current.payments}
            >
              <p className="text40class">{t('Payments')}</p>

              {paymentsQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
            {/* PRICES AND BILLING QUESTION */}
            <div
              className="mt-20 max_md:mt-[60px] max_sm:mt-10"
              id="prices"
              ref={sectionRefs.current.prices}
            >
              <p className="text40class">{t('PricesBilling')}</p>

              {pricesQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
            {/* SUPPORT QUESTION */}
            <div
              className="mt-20 max_md:mt-[60px] max_sm:mt-10"
              id="support"
              ref={sectionRefs.current.support}
            >
              <p className="text40class ">
                {t('Support')}
              </p>

              {supportQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
            {/* Security Compliance QUESTION */}
            <div
              className="mt-20 max_md:mt-[60px] max_sm:mt-10"
              id="security"
              ref={sectionRefs.current.security}
            >
              <p className="text40class ">
                {t('SecurityCompliance')}
              </p>

              {securityQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer data={data} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};

export default AllQuestions;
