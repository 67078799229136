import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagsComp from '../../utils/TagsComp';
import CommonSizeContainer from '../../CommonSizeContainer';
import {
  Confirmation,
  CustomerInteIcon,
  GenerationIcon,
  ImprovedCash,
  LineAnimated,
  LineAnimatedRes,
  LineAnimatedResTab,
  PayAuth,
  PredictableRevenue,
  Processing,
  SMSIcon,
  StraitLine,
  TimeSavings,
} from '../../../assets/Icons/Products/ProductsIcon';
import CardContainer from '../CardContainer';
import useVisibility from '../../../Hooks/useVisibility';
import { useWindowSize } from '../../../Hooks/useWindowSize';

const PaymentPlans = () => {
  const { t } = useTranslation();
  const animateRef = useRef();
  const [serviceList, setServiceList] = useState([]);
  const isAnimating = useVisibility(animateRef, 0.7);
  const [startAnim, setStartAnim] = useState(false);
  const isAnimatingLine = useVisibility(animateRef, 0.4);
  const [startAnimLine, setStartAnimLine] = useState(false);
  const [paymentLink,setPaymentLink] = useState([])
  const intervals = [200, 600, 500, 1900, 900, 1400];
  const intervalsRes = [200, 850, 900, 700, 900, 2800];
  const [count, setCount] = useState(1);
  const [countRes, setCountRes] = useState(1);
  const [visitedDiv] = useState([]);
  const [visitedResDiv] = useState([]);
  const { width: windowWidth } = useWindowSize();
  const isSmallDesktop = windowWidth <= 1300;
  const isTabView = windowWidth <= 980;
  const isMobileView = windowWidth <= 720;
  useEffect(() => {
    setServiceList(getTranslatedList(t, 'services'));
    setPaymentLink(getTranslatedList(t, 'payment_link'));
  }, [t]);

  useEffect(() => {
    if (!startAnim && isAnimating) {
      setStartAnim(true);
    }
    if (isMobileView) {
      if (!startAnimLine && isAnimatingLine) {
        setStartAnimLine(true);
      }
    }
  }, [isAnimating, isAnimatingLine]);

  useEffect(() => {
    if (startAnim && !isMobileView) {
      if (count < 7) {
        // Only set interval if count is less than 5
        const interval = setInterval(() => {
          // setActiveShadow((prevActiveShadow) => (prevActiveShadow + 1) % 5);
          visitedDiv.push(count);
          setCount((prevCount) => prevCount + 1);
        }, intervals[count - 1]);

        return () => clearInterval(interval);
      }
    }
  }, [count, startAnim]);

  useEffect(() => {
    if (isMobileView) {
      if (startAnimLine) {
        if (countRes < 7) {
          const interval = setInterval(() => {
            visitedResDiv.push(countRes);
            setCountRes((prevCount) => prevCount + 1);
          }, intervalsRes[countRes - 1]);

          return () => clearInterval(interval);
        }
      }
    }
  }, [countRes, startAnimLine]);
  return (
    <>
      <CommonSizeContainer>
        <div className="flex flex-col items-center" id="PaymentPlan">
          <TagsComp
            px="16px"
            py="8px"
            text={t('RecurringPayments')}
            color="#00A0FC"
            borderColor="#00A0FC"
          />

          <h2 className="text50Product mt-4 max_md:mt-[30px] max_sm:mt-4">
            {t('PaymentPlans')}
          </h2>
          <p className="paragraphText font-normal mt-4 text-center">
            {t('PaymentPlansT')}
          </p>
          <div className="max_md:hidden max_720:block">
            <div className="grid grid-cols-3 gap-6 max_xl:gap-4 mt-[60px] max_720:mt-10 max_720:grid-cols-1 max_720:gap-5">
              {serviceList?.map((service, index1) => (
                <CardContainer key={index1} service={service} minHeight />
              ))}
            </div>
          </div>
          <div className="hidden max_md:block max_720:hidden mt-[60px]">
            <div className="flex justify-center">
              <CardContainer service={serviceList[0]} isFixWidth />
            </div>
            <div className="flex justify-center gap-[30px] mt-[30px]">
              <CardContainer service={serviceList[1]} isFixWidth />
              <CardContainer service={serviceList[2]} isFixWidth />
            </div>
          </div>
        </div>
      </CommonSizeContainer>
      <CommonSizeContainer>
        <div ref={animateRef} id="PaymentLinks">
          <p className="text50 text-center">{t('RequestingPayments')}</p>
          <p className="paragraphText font-normal mt-4 text-center">
            {t('RequestingPaymentsT')}
          </p>

          <div className="mt-[130px] relative w-max max_xl:w-full m-auto max_md:w-[640px] max_md:mt-20 max_720:hidden">
            {isTabView ? (
              <LineAnimatedResTab
                startAnim={startAnim}
                style={{ width: '100%' }}
              />
            ) : isSmallDesktop ? (
              <LineAnimatedRes
                startAnim={startAnim}
                style={{ width: '99.5%' }}
              />
            ) : (
              <LineAnimated startAnim={startAnim} />
            )}

            <div className="absolute top-[-30px] flex max_md:gap-3 justify-between left-[58px] w-[1034px] max_xl:w-[920px] max_xl:left-[28px] max_xl:top-1 max_md:w-[96%] max_md:top-[5px] max_md:left-[10px]">
              <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
                <div
                  className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                    visitedDiv.includes(1)
                      ? 'shadow-animate-product  border-ButtonPrimary'
                      : 'border-LightBright'
                  }`}
                >
                  <GenerationIcon
                    color={visitedDiv.includes(1) ? '#00A0FC' : '#6D77A0'}
                  />
                </div>
                <p className="mt-3 max_xl:mt-2 subParagraphText max_md:text-textMedium text-LightPrimary font-medium text-center">
                  {t('Generation')}
                </p>
                <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                  {t('GenerationT')}
                </p>
              </div>
              <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
                <div
                  className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                    visitedDiv.includes(2)
                      ? 'shadow-animate-product  border-ButtonPrimary'
                      : 'border-LightBright'
                  }`}
                >
                  <SMSIcon
                    color={visitedDiv.includes(2) ? '#00A0FC' : '#6D77A0'}
                  />
                </div>
                <p className="mt-3 max_xl:mt-2 subParagraphText max_md:text-textMedium text-LightPrimary font-medium text-center">
                  {t('Delivery')}
                </p>
                <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                  {t('DeliveryT')}
                </p>
              </div>
              <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
                <div
                  className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                    visitedDiv.includes(3)
                      ? 'shadow-animate-product  border-ButtonPrimary'
                      : 'border-LightBright'
                  }`}
                >
                  <CustomerInteIcon
                    color={visitedDiv.includes(3) ? '#00A0FC' : '#6D77A0'}
                  />
                </div>
                <p className="mt-3 max_xl:mt-2 subParagraphText max_md:text-textMedium text-LightPrimary font-medium text-center">
                  {t('CustomerInteraction')}
                </p>
                <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                  {t('CustomerInteractionT')}
                </p>
              </div>
            </div>
            <div className="absolute bottom-[-180px] max_xl:bottom-[-133px] flex max_md:gap-3 justify-between right-[50px] max_xl:right-[45px] w-[1034px] max_xl:w-[920px] max_md:w-[98%] max_md:bottom-[-134px] max_md:right-[13px]">
              <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
                <div
                  className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                    visitedDiv.includes(4)
                      ? 'shadow-animate-product  border-ButtonPrimary'
                      : 'border-LightBright'
                  }`}
                >
                  <PayAuth
                    color={visitedDiv.includes(4) ? '#00A0FC' : '#6D77A0'}
                  />
                </div>
                <p className="mt-3 max_xl:mt-2 subParagraphText max_md:text-textMedium text-LightPrimary font-medium text-center">
                  {t('PaymentAuthorization')}
                </p>
                <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                  {t('PaymentAuthT')}
                </p>
              </div>
              <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
                <div
                  className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                    visitedDiv.includes(5)
                      ? 'shadow-animate-product  border-ButtonPrimary'
                      : 'border-LightBright'
                  }`}
                >
                  <Processing
                    color={visitedDiv.includes(5) ? '#00A0FC' : '#6D77A0'}
                  />
                </div>
                <p className="mt-3 max_xl:mt-2 subParagraphText max_md:text-textMedium text-LightPrimary font-medium text-center">
                  {t('Processing')}
                </p>
                <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                  {t('ProcessingT')}
                </p>
              </div>
              <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
                <div
                  className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                    visitedDiv.includes(6)
                      ? 'shadow-animate-product  border-ButtonPrimary'
                      : 'border-LightBright'
                  }`}
                >
                  <Confirmation
                    color={visitedDiv.includes(6) ? '#00A0FC' : '#6D77A0'}
                  />
                </div>
                <p className="mt-3 max_xl:mt-2 subParagraphText max_md:text-textMedium text-LightPrimary font-medium text-center">
                  {t('Confirmation')}
                </p>
                <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                  {t('ConfirmationT')}
                </p>
              </div>
            </div>
          </div>

          {/* Mobile tab view */}
          <div className="mt-10 hidden max_720:block">
            <div className="pl-10 relative">
              <StraitLine startAnim={startAnimLine} />

              <div className="absolute top-0 left-3 max_sm:left-[14px] flex flex-col">
                {paymentLink.map((paymentData, index) => (
                  <div
                    className="flex gap-5"
                    key={index}
                    style={{ marginTop: paymentData.mt }}
                  >
                    <div
                      className={`w-[60px] max_sm:w-[50px] max_sm:h-[50px] h-[60px] flex justify-center p-3 max_sm:p-2 rounded-full border-[1px] bg-white ${
                        visitedResDiv.includes(paymentData.id)
                          ? 'shadow-animate-product  border-ButtonPrimary'
                          : 'border-LightBright'
                      }`}
                    >
                      {React.cloneElement(paymentData.icon, {
                        color: visitedResDiv.includes(paymentData.id)
                          ? '#00A0FC'
                          : '#6D77A0',
                      })}
                    </div>
                    <div className="max-w-[300px] w-[calc(100%-80px)]">
                      <p className="subParagraphText text-LightPrimary font-medium max_sm:text-headingNormal">
                        {paymentData.name}
                      </p>
                      <p className="mt-2 smallText16Normal font-medium max_md:leading-5">
                        {paymentData.text}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CommonSizeContainer>

      <CommonSizeContainer>
        <div
          className="flex flex-col gap-6 max_md:gap-4 pt-[140px] max_720:pt-[60px] max_sm:pt-[100px] max_350:pt-[200px]"
          id="BudgetCalculatorId"
        >
          <TagsComp
            px="16px"
            py="8px"
            text={t('BudgetCalculator')}
            color="#00A0FC"
            borderColor="#00A0FC"
          />
          <h2 className="text50Product max_md:mt-[14px] max_sm:mt-0">
            {t('BudgetCalculatorT')}
          </h2>
          <p className="paragraphText leading-[30px] max_sm:leading-[21px]">
            {t('BudgetCalculatorT1')}
          </p>
          <div className="mt-4 max_md:mt-9 max_sm:mt-4 flex max_md:flex-col gap-6 justify-center p-5 bg-LightBgSecondary max_md:p-10 max_sm:p-5">
            <div>
              <img
                src="https://images.dcdial.com/dcdial.com/v2/BudgetCalculator.svg"
                alt="BudgetCalculator"
              />
            </div>
            <div className="flex flex-col gap-6">
              <div>
                <img
                  src="https://images.dcdial.com/dcdial.com/v2/MonthlyIncome.svg"
                  alt="MonthlyIncome"
                />
              </div>
              <div>
                <img
                  src="https://images.dcdial.com/dcdial.com/v2/MonthlyExpense.svg"
                  alt="MonthlyExpense"
                />
              </div>
            </div>
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    services: [
      {
        title: t('PredictableRevenue'),
        text: t('PredictableRevenueT'),
        icon: <PredictableRevenue />,
      },
      {
        title: t('ImprovedCash'),
        text: t('ImprovedCashT'),
        icon: <ImprovedCash />,
      },
      {
        title: t('TimeSavings'),
        text: t('TimeSavingsT'),
        icon: <TimeSavings />,
      },
    ],
    payment_link: [
      {
        id: 1,
        name: t('Generation'),
        text: t('GenerationT'),
        icon: <GenerationIcon />,
        mt: '0px',
      },
      {
        id: 2,
        name: t('Delivery'),
        text: t('DeliveryT'),
        icon: <SMSIcon />,
        mt: '52px',
      },
      {
        id: 3,
        name: t('CustomerInteraction'),
        text: t('CustomerInteractionT'),
        icon: <CustomerInteIcon />,
         mt: '52px'
      },
      {
        id: 4,
        name: t('PaymentAuthorization'),
        text: t('PaymentAuthT'),
        icon: <PayAuth />,
         mt: '56px'
      },
      {
        id: 5,
        name: t('Processing'),
        text: t('ProcessingT'),
        icon: <Processing />,
         mt: '56px'
      },
      {
        id: 6,
        name: t('Confirmation'),
        text: t('ConfirmationT'),
        icon: <Confirmation />,
         mt: '56px'
      },
    ],
  };

  return lists[listKey];
};
export default PaymentPlans;
