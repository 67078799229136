import React, { useEffect, useState } from 'react';
import CommonSizeContainer from '../CommonSizeContainer';
import {
  CostSavingsIcon,
  CrossChannelIcon,
  CustomizableCall,
  EfficientCallIcon,
  IncreaseFirstCallIcon,
  ReduceWaitIcon,
  ScaleOperationsIcon,
  TargeatedCampIcon,
  UnifiedIcon,
} from '../../assets/Icons/Products/ProductsIcon';
import { useTranslation } from 'react-i18next';
import CardContainer from './CardContainer';
import { useWindowSize } from '../../Hooks/useWindowSize';

const ProductServices = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const { t } = useTranslation();
  const [serviceList, setServiceList] = useState({
    services: [],
    SRList: [],
  });
  useEffect(() => {
    setServiceList((prevList) => ({
      ...prevList,
      services: getTranslatedList(t, 'services'),
      SRList: getTranslatedList(t, 'smart_routing'),
    }));
  }, [t]);
  return (
    <>
      <div id="UnifiedCommunications">
        <CommonSizeContainer>
          <div className="max_md:hidden max_720:block">
            <div className="grid grid-cols-3 max_720:grid-cols-1 max_720:gap-[30px] gap-6 max_xl:gap-4">
              {serviceList.services?.map((service, index1) => (
                <CardContainer key={index1} service={service} />
              ))}
            </div>
          </div>
          <div className="hidden max_md:block max_720:hidden">
            <div className="flex justify-center">
              <CardContainer service={serviceList?.services[0]} isFixWidth />
            </div>
            <div className="flex justify-center gap-[30px] mt-[30px]">
              <CardContainer service={serviceList?.services[1]} isFixWidth />
              <CardContainer service={serviceList?.services[2]} isFixWidth />
            </div>
          </div>
        </CommonSizeContainer>
      </div>
      <CommonSizeContainer>
        <h2 className="text50Product text-center" id="SmartRouting">
          {t('SmartRoutingTitle')}
        </h2>
        <div className="grid grid-cols-3 gap-6 max_xl:gap-4 mt-[60px] max_600:mt-10 max_md:grid-cols-2 max_600:grid-cols-1">
          {serviceList.SRList?.map((service, index1) => (
            <div
              key={index1}
              className="bg-BgPrimary rounded-[20px] p-[30px] max_xl:p-5 flex flex-col "
            >
              <div>
                {React.cloneElement(service.icon, {
                  style: {
                    width: isMobileView ? 43 : 66,
                    height: isMobileView ? 44 : 61,
                  },
                })}
              </div>
              <p className="subHeadingText mt-6 max_sm:mt-5 max_xl:min-h-[75px] max_md:min-h-0">
                {service.title}
              </p>
              <p className="subParagraphText font-normal mt-3">
                {service.text}
              </p>
            </div>
          ))}
        </div>
      </CommonSizeContainer>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    services: [
      {
        title: t('UnifiedCommunications'),
        text: t('UnifiedCommunicationText'),
        icon: <UnifiedIcon />,
      },
      {
        title: t('TargetedCampaigns'),
        text: t('TargetedCampaignsT'),
        icon: <TargeatedCampIcon />,
      },
      {
        title: t('CrossChannelData'),
        text: t('CrossChannelDataT'),
        icon: <CrossChannelIcon />,
      },
    ],
    smart_routing: [
      {
        title: t('EfficientCall'),
        text: t('EfficientCallT'),
        icon: <EfficientCallIcon />,
      },
      {
        title: t('ReduceWait'),
        text: t('ReduceWaitT'),
        icon: <ReduceWaitIcon />,
      },
      {
        title: t('IncreaseFirstCall'),
        text: t('IncreaseFirstCallT'),
        icon: <IncreaseFirstCallIcon />,
      },
      {
        title: t('ScaleOperations'),
        text: t('ScaleOperationsT'),
        icon: <ScaleOperationsIcon />,
      },
      {
        title: t('CostSavings'),
        text: t('CostSavingsT'),
        icon: <CostSavingsIcon />,
      },
      {
        title: t('CustomizableCall'),
        text: t('CustomizableCallT'),
        icon: <CustomizableCall />,
      },
    ],
  };

  return lists[listKey];
};

export default ProductServices;
